import { NgModule } from '@angular/core';
import { AngularMaterialModule } from '../../../../shared/modules/angular-material/angular-material.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SftButtonModule } from '../../../../stories/components/button/button.module';
import { SftInputModule } from '../../../../stories/components/Input/input.module';
import { SftSwitchButtonModule } from '../../../../stories/components/switch/switch.module';
import { StepperComponent } from '../../../../stories/components/stepper/stepper.component';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { StorybookGlobalModule } from 'src/stories/components/storybook-global.module';
import { RouterModule } from '@angular/router';
import { CostCenterRoutingModule } from './cost-center-routing.module';
import { CostCenterListingComponent } from './components/cost-center-listing/cost-center-listing.component';
import { CostCenterDetailComponent } from './components/cost-center-detail/cost-center-detail.component';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { FilterCostCenterSidebarComponent } from './components/cost-center-listing/filter-sidebar/filter-cost-center-sidebar.component';

@NgModule({
  declarations: [
    CostCenterListingComponent,
    CostCenterDetailComponent,
    FilterCostCenterSidebarComponent,
  ],
  imports: [
    LoaderComponent,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    AngularMaterialModule,
    SidebarModule,
    SftButtonModule,
    SftInputModule,
    SftSwitchButtonModule,
    StepperComponent,
    SharedModule,
    StorybookGlobalModule,
    RouterModule,
    CostCenterRoutingModule,
  ],
})
export class CostCenterModule {}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CostCenterService } from '../../services/cost-center.service';
import { CostCenterDailogComponent } from 'src/shared/components/dialog/admin-dialogs/cost-center-dialog/cost-center-dialog';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { debounceTime, takeUntil } from 'rxjs';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CoreTeamService } from 'src/app/admin-portal/modules/core-team/services/core-team.service';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PageEvent } from '@angular/material/paginator';

export interface CoreTeam {
  resourceName: string;
  role: string;
  resourceSalary: number;
  operationalCost: string;
  startDate: string;
}

export interface Project {
  name: string;
  costModal: number;
  budget: string;
  status: string;
  totalResource: string;
}

interface ActivityLog {
  timestamp: string;
  user: {
    name: string;
  };
  activityDetail: string;
}

@Component({
  selector: 'app-cost-center-detail',
  templateUrl: './cost-center-detail.component.html',
})
export class CostCenterDetailComponent implements OnInit {
  id!: string;
  isSidebarOpen = false;
  isDrawerOpen = false;
  drawerWidth = 200;
  isActive = false;
  centerName!: string;
  code!: string;
  centerLeader!: string;
  marginRate!: string;
  accountName!: string;
  lastUpdated!: string;
  element: any;
  loading: boolean = false;
  projectList: any[] = [];
  coreTeamList: any[] = [];
  coreTeamPageNumber: number = 0;
  projectPageNumber: number = 0;
  activityPageNumber: number = 0;
  coreTeamPageSize: number = 25;
  projectPageSize: number = 25;
  activityPageSize: number = 25;
  search: string = '';
  sortBy: any = 'createdAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  cost_centerID: string = '';
  coreTeamForm!: FormGroup;
  projectForm!: FormGroup;
  activityForm!: FormGroup;
  currencyCoreTeam!: string;
  currencyProject!: string;
  lastSortBy: string = '';
  totalProjectRecords: number = 0;
  totalCoreTeamRecords: number = 0;
  totalActivityRecords: number = 0;
  activityLogsGrouped: { date: string; logs: ActivityLog[] }[] = [];
  selectedTabIndex: number = 0;
  selectTab: string = 'Associated Core Team';
  canManageResources: boolean = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private costService: CostCenterService,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private projectService: ProjectService,
    private coreTeamService: CoreTeamService,
    private formbuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    const permissions = localStorage.getItem('modules');
    if (permissions) {
      const userPermissions = JSON.parse(permissions);
      const resourcePermissions = userPermissions.find(
        (p: any) => p.module === 'cost_center'
      );
      if (
        resourcePermissions &&
        resourcePermissions.permission.includes('view') &&
        resourcePermissions.permission.includes('manage')
      ) {
        this.canManageResources = true;
      }
    }

    this.id = this.route.snapshot.params['id'];
    this.fetchCostCenterDetails();
    this.coreTeamForm = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchCoreTeam();

    this.projectForm = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchProject();

    this.activityForm = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchActivityLogs();

    this.selectedTabIndex = 0;
    this.onTabChange({ index: 0 } as MatTabChangeEvent);
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    switch (this.selectedTabIndex) {
      case 0:
        this.selectTab = 'Associated Core Team';
        this.getCoreTeamByCostCenterId(
          this.coreTeamPageNumber,
          this.coreTeamPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );

        break;
      case 1:
        this.selectTab = 'Associated Project';
        this.getProjectByCostCenterId(
          this.projectPageNumber,
          this.projectPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
        break;
      case 2:
        this.selectTab = 'Activity';
        this.fetchActivityLogs();
        break;
    }
  }

  onCoreTeamPageChange(event: PageEvent): void {
    this.coreTeamPageNumber = event.pageIndex;
    this.coreTeamPageSize = event.pageSize;
    this.getCoreTeamByCostCenterId(
      this.coreTeamPageNumber,
      this.coreTeamPageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );
  }

  onProjectPageChange(event: PageEvent): void {
    this.projectPageNumber = event.pageIndex;
    this.projectPageSize = event.pageSize;
    this.getProjectByCostCenterId(
      this.projectPageNumber,
      this.projectPageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );
  }

  onActivityPageChange(event: PageEvent): void {
    this.activityPageNumber = event.pageIndex;
    this.activityPageSize = event.pageSize;
    this.fetchActivityLogs();
  }

  fetchCostCenterDetails(): void {
    this.costService.getCostListById(this.id).subscribe((res) => {
      const costCenterData = res.data;
      this.element = costCenterData;
      this.centerName = costCenterData?.centerName;
      this.code = costCenterData?.code;
      this.centerLeader = costCenterData?.centerLeader;
      this.accountName = costCenterData?.account?.name;
      this.marginRate = costCenterData?.marginRate;
      this.lastUpdated = costCenterData?.modifiedAt;
    });
  }

  coreTeamColumns: string[] = [
    'resourceName',
    'role',
    'resourceSalary',
    'operationalCost',
    'startDate',
  ];
  dataSource = new MatTableDataSource<CoreTeam>([]);

  projectColumns: string[] = [
    'name',
    'costModal',
    'budget',
    'status',
    'totalResource',
  ];
  dataSource2 = new MatTableDataSource<Project>([]);

  openEditCostCenterDialog(element: any): void {
    const dialogRef = this.dialog.open(CostCenterDailogComponent, {
      height: 'auto',
      width: '467px',
      data: { isEditMode: true, element: element },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.fetchCostCenterDetails();
      }
    });
  }

  // Associated Core Team
  onSortChangeCoreTeam(event: Sort): void {
    const sortByCoreTeam = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortByCoreTeam !== this.lastSortBy) {
      this.coreTeamPageNumber = 0;
    }
    this.lastSortBy = sortByCoreTeam;

    this.coreTeamService
      .getCoreTeamByCostCenterId(
        this.coreTeamPageNumber,
        this.coreTeamPageSize,
        this.search,
        sortByCoreTeam,
        validOrderBy,
        this.id
      )
      .subscribe({
        next: (data: any) => {
          this.coreTeamList = data?.data?.records || [];
          this.dataSource.data = this.coreTeamList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Core Team List request:', error);
        },
      });
  }

  getCoreTeamByCostCenterId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    cost_centerID: string
  ): void {
    this.loading = true;
    this.coreTeamService
      .getCoreTeamByCostCenterId(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        cost_centerID
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.coreTeamList = data?.data?.records;
          this.currencyCoreTeam =
            data.data.records[0]?.costCenter?.account?.financials[0]?.currency;
          this.totalCoreTeamRecords = data?.data?.count || 0;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in get Core Team list request:', error);
          this.loading = false;
        },
      });
  }

  searchCoreTeam(): void {
    this.coreTeamForm
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getCoreTeamByCostCenterId(
          this.coreTeamPageNumber,
          this.coreTeamPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      });
  }

  // Associated Projects
  isStatusOngoing(status: string): boolean {
    return (
      !!status &&
      (status.toLowerCase() === 'active' || status.toLowerCase() === 'ongoing')
    );
  }

  isStatusOnHold(status: string): boolean {
    return !!status && status.toLowerCase() === 'hold';
  }

  isStatusCompleted(status: string): boolean {
    return !!status && status.toLowerCase() === 'completed';
  }

  getFormattedStatus(status: string): string {
    switch (status.toLowerCase()) {
      case 'active':
        return 'Ongoing';
      case 'hold':
        return 'On hold';
      case 'completed':
        return 'Completed';
      default:
        return '';
    }
  }

  onSortChangeProject(event: Sort): void {
    const sortByProject = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortByProject !== this.lastSortBy) {
      this.projectPageNumber = 0;
    }
    this.lastSortBy = sortByProject;

    this.projectService
      .getProjectByCostCentre(
        this.projectPageNumber,
        this.projectPageSize,
        this.search,
        sortByProject,
        validOrderBy,
        this.id
      )
      .subscribe({
        next: (data: any) => {
          this.projectList = data?.data?.records || [];
          this.dataSource.data = this.projectList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Project List request:', error);
        },
      });
  }

  getProjectByCostCenterId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    cost_centerID: string
  ): void {
    this.loading = true;
    this.projectService
      .getProjectByCostCentre(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        cost_centerID
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.projectList = data?.data?.records;
          this.currencyProject = data.data.records[0]?.currency;
          this.totalProjectRecords = data?.data?.count || 0;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in get Project list request:', error);
          this.loading = false;
        },
      });
  }

  searchProject(): void {
    this.projectForm
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getProjectByCostCenterId(
          this.projectPageNumber,
          this.projectPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      });
  }

  fetchActivityLogs(): void {
    this.loading = true;
    this.costService
      .getActivityLogs(
        this.activityPageNumber,
        this.activityPageSize,
        this.search,
        'timestamp',
        'DESC'
      )
      .subscribe((res) => {
        const logs: ActivityLog[] = res.data.records;
        this.totalActivityRecords = res?.data?.count || 0;
        const groupedLogs: { [date: string]: ActivityLog[] } = {};
        logs.forEach((log: ActivityLog) => {
          const date = new Date(log.timestamp).toDateString();
          if (!groupedLogs[date]) {
            groupedLogs[date] = [];
          }
          groupedLogs[date].push(log);
        });
        this.activityLogsGrouped = Object.entries(groupedLogs).map(
          ([date, logs]) => ({
            date,
            logs,
          })
        );
        this.loading = false;
      });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (this.isToday(date)) {
      return `Todays - ${this.formatDateToString(date)}`;
    } else if (this.isYesterday(date)) {
      return `Yesterday - ${this.formatDateToString(date)}`;
    } else {
      return this.formatDateToString(date);
    }
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  isYesterday(date: Date): boolean {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }

  private formatDateToString(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return date.toLocaleDateString('en-US', options);
  }

  getDefaultPhotoText(name: string): string {
    if (!name) return '';

    const names = name.trim().split(/\s+/);
    let firstChar = '';
    let secondChar = '';

    if (names.length > 0) {
      firstChar = names[0].charAt(0);
      if (names.length > 1) {
        secondChar = names[1].charAt(0);
      }
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  searchActivityLogs(): void {
    this.loading = true;
    this.activityForm
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.fetchActivityLogs();
      });
  }

  isValidNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  goBack() {
    this.router.navigateByUrl('/cost-center');
  }
}

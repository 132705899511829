import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CostCenterListingComponent } from './components/cost-center-listing/cost-center-listing.component';
import { CostCenterDetailComponent } from './components/cost-center-detail/cost-center-detail.component';

const routes: Routes = [
  {
    path: '',
    component: CostCenterListingComponent,
  },
  {
    path: 'view/:id',
    component: CostCenterDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CostCenterRoutingModule {}

<div class="main-content body-bg listing-wrapper">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong" style="margin-bottom: 0">
          Cost Center
        </h4>
        <p class="medium mat-body-1 textXS text" style="margin-top: 0">
          Manage Your Cost Center
        </p>
      </div>
      <form [formGroup]="form">
        <div class="d-flex align-center">
          <mat-form-field appearance="outline" class="search-input">
            <input
              formControlName="searchQuery"
              type="text"
              matInput
              placeholder="Search"
              (keydown.enter)="$event.preventDefault()"
            />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
          <div class="d-flex mr-2 justify-between header-info-wrapper">
            <button
              mat-button
              class="outline-button"
              (click)="openFilterDrawer()"
            >
              <img src="/images/filter.png" alt="" class="mr-2" />
              <div class="d-flex align-center">
              <span class="mr-2">Filter</span>
              <div *ngIf="isFilterApplied" class="active-circle"></div>
            </div>
            </button>
          </div>

          <sft-button
            [style.cursor]="!canManageResources ? 'not-allowed' : 'pointer'"
            [label]="'Add Cost Center'"
            [color]="'primary'"
            [variant]="'flat'"
            [icon]="'home'"
            [size]="'xl'"
            [disable]="!canManageResources"
            [matTooltip]="
              canManageResources
                ? ''
                : 'You do not have permission to add cost center'
            "
            (buttonClick)="openAddDialog()"
          ></sft-button>
        </div>
      </form>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <div
      class="required-star"
      *ngIf="!loading && costCenter.length === 0"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 200px);
        font-size: 20px;
      "
    >
      No cost centers found!
    </div>
    <div
      class="body-bg listing-content overflow-scroll cost-listing-wrapper"
      *ngIf="!loading && costCenter.length > 0"
    >
      <table
        mat-table
        [dataSource]="costCenter"
        matSort
        class="custom-action-table"
        style="cursor: pointer"
        (matSortChange)="onSort($event)"
      >
        <ng-container matColumnDef="code">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="listing-code"
          >
            Code
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/cost-center/view/' + element.cc_cost_centerID"

          >
            <div class="d-flex align-center code-col">
              <span   title="{{
                element.cc_code ? '' + element.cc_code.toUpperCase() : '--'
              }}">{{
                element.cc_code ? "" + element.cc_code.toUpperCase() : "--"
              }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="centerName"
            class="listing-name"
          >
            Cost Center Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/cost-center/view/' + element.cc_cost_centerID"
            class="cost-name  cost-leader-col table-ellipse"
            title="{{ element.cc_center_name }}"
          >
            {{ element.cc_center_name  || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="leader">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="centerLeader"
            class="listing-name cost-leader-col table-ellipse"
          >
            Cost Center Leader
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/cost-center/view/' + element.cc_cost_centerID"
            title=" {{ element.cc_center_leader || '--' }}"
            class="cost-leader-col table-ellipse"
          >
            {{ element.cc_center_leader || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="account">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="accountName"
            class="listing-name"
          >
            Account
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/cost-center/view/' + element.cc_cost_centerID"
            title="{{ element.ac_name || '--' }}"
            class="cost-leader-col table-ellipse"
          >
            {{ element.ac_name || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="rate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="marginRate"
            class="rate"
          >
            Margin Rate (%)
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/cost-center/view/' + element.cc_cost_centerID"
            title="{{ element.cc_margin_rate || '--' }}"
          >
            {{ element.cc_margin_rate | truncate : 10 || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="rate">
            Revenue Date
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/cost-center/view/' + element.cc_cost_centerID"
          >
            {{ element.date ? "$" + element.date : "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastupdated">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="modifiedAt"
            sortActionDescription="Sort by date"
            class="updated-col"
          >
            Last Updated
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/cost-center/view/' + element.cc_cost_centerID"
          >
            {{
              element.cc_modified_at
                ? (element.cc_modified_at | date : "d MMM y h:mm a" : "UTC")
                : "--"
            }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="action-table-header-data"
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          class="action-table-body-data"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator
        class="action-table-pagination fixed bottom-0 cost-center"
        [length]="totalRecords"
        [pageSize]="limit"
        [pageIndex]="pageNumber"
        [pageSizeOptions]="[25, 50, 75, 100]"
        (page)="onPageChange($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </div>
</div>
<app-cost-center-dialog-filter
  #filter
  (filtersApplied)="handleFilterApplied($event)"
></app-cost-center-dialog-filter>

<div class="main-content body-bg p-6 cost-listing">
  <p class="d-flex align-center textXS fw-400 pb-4">
    <mat-icon style="cursor: pointer" class="pr-2" (click)="goBack()"
      >arrow_back</mat-icon
    >Back to List Cost Center Details
  </p>
  <div class="d-flex">
    <div class="accounts-detail sidebar-width mr-4">
      <div class="side-pannel-info">
        <div class="p-6 d-coloumn h-m">
          <div>
            <div class="d-flex justify-between mb-6">
              <div class="">
                <span class="mat-caption text fw-600 d-block mb-2" style="margin-right: 12px; width: 171px; word-wrap: break-word;">{{
                  centerName
                }}</span>
                <span>{{ code ? "" + code.toUpperCase() : "--" }}</span>
              </div>
              <div>
                <sft-button
                  [style.cursor]="
                    !canManageResources ? 'not-allowed' : 'pointer'
                  "
                  [label]="'Edit'"
                  [color]="'primary'"
                  [variant]="'stroked'"
                  [disable]="false"
                  [icon]="'home'"
                  [disable]="!canManageResources"
                  [matTooltip]="
                    canManageResources
                      ? ''
                      : 'You do not have permission to edit cost center'
                  "
                  [size]="'medium'"
                  (buttonClick)="openEditCostCenterDialog(element)"
                ></sft-button>
              </div>
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2"
                >Cost Center Leader</span
              >
              <span class="textS text grey-text" [title]="centerLeader || '--'">
                {{ centerLeader | truncate : 10 || "--" }}</span
              >
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2">Account</span>
              <span class="textS text grey-text" [title]="accountName || '--'">
                {{ accountName | truncate : 10 || "--" }}'s</span
              >
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2"
                >Revenue Till Date</span
              >
              <span class="textS text grey-text">--</span>
            </div>
            <div class="mb-6">
              <span class="fw-500 textS text d-block mb-2"
                >Margin Rate (%)</span
              >
              <span class="textS text grey-text" [title]="marginRate || '--'">
                {{ marginRate | truncate : 10 || "--" }}</span
              >
            </div>
          </div>
          <!-- <div class="text-center pt-6">
            <p class="textXS fw-400 text-grey4">
              last Update: {{ lastUpdated | date : "d MMM y" }}
              {{ lastUpdated | date : "h:mm a" : "UTC" }}
            </p>
          </div> -->
        </div>
      </div>
    </div>

    <div class="account-detail-tab with-scroll">
      <div>
        <div class="pt-6 px-6 pb-0">
          <mat-tab-group
            animationDuration="0ms"
            [(selectedIndex)]="selectedTabIndex"
            (selectedTabChange)="onTabChange($event)"
          >
            <!-- Associated Core Team -->
            <mat-tab label="Associated Core Team">
              <div class="pb-6 pt-6">
                <div
                  class="d-flex justify-between align-center account-detail-actions"
                >
                  <div class="search-block">
                    <mat-form-field
                      appearance="outline"
                      class="search-input"
                      [formGroup]="coreTeamForm"
                    >
                      <input
                        type="text"
                        matInput
                        placeholder="Search"
                        formControlName="searchQuery"
                      />
                      <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="account-detail-table">
                <app-loader *ngIf="loading"></app-loader>
                <ng-container *ngIf="!loading">
                  <table
                    mat-table
                    [dataSource]="coreTeamList"
                    matSort
                    (matSortChange)="onSortChangeCoreTeam($event)"
                  >
                    <ng-container matColumnDef="resourceName">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header="resourceName"
                        class="name"
                      >
                        Name
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="grey-text name-col table-ellipse"
                        title="{{ element?.rs_name }}"
                      >
                        {{ element?.rs_name }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="role">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header="role"
                        class="name"
                      >
                        Role
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="grey-text name-col table-ellipse"
                        title="{{ element?.ct_role }}"
                      >
                        {{ element?.ct_role }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="resourceSalary">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header="resourceSalary"
                        class="name name-col"
                      >
                        Salary{{
                          currencyCoreTeam ? " (" + currencyCoreTeam + ")" : ""
                        }}
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="grey-text name-col table-ellipse"
                        title="{{
                          element.ct_resource_salary !== null &&
                          isValidNumber(element.ct_resource_salary)
                            ? (element.ct_resource_salary | number : '1.2-2')
                            : '--'
                        }}"
                      >
                        {{
                          element.ct_resource_salary !== null &&
                          isValidNumber(element.ct_resource_salary)
                            ? (element.ct_resource_salary | number : "1.2-2")
                            : "--"
                        }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="operationalCost">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="name"
                      >
                        Cost
                        {{
                          currencyCoreTeam ? " (" + currencyCoreTeam + ")" : ""
                        }}
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="grey-text name-col table-ellipse"
                        title="{{ element?.ct_operational_cost }}"
                      >
                        {{ element?.ct_operational_cost }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="startDate">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="name"
                      >
                        Date
                      </th>
                      <td mat-cell *matCellDef="let element" class="grey-text">
                        {{ element?.ct_start_date | date : "d MMM y" }}
                      </td>
                    </ng-container>
                    <tr
                      mat-header-row
                      class="account-detail-header"
                      *matHeaderRowDef="coreTeamColumns"
                    ></tr>
                    <tr
                      mat-row
                      class="account-detail-body"
                      *matRowDef="let row; columns: coreTeamColumns"
                    ></tr>
                  </table>
                </ng-container>
              </div>
              <div *ngIf="coreTeamList.length === 0" class="text-center mt-6">
                <p class="required-star" style="font-size: 1.5rem">
                  No associated core team members found.
                </p>
              </div>
              <mat-paginator
                *ngIf="coreTeamList.length > 0"
                class="action-table-pagination expense activity"
                [length]="totalCoreTeamRecords"
                [pageSize]="coreTeamPageSize"
                [pageIndex]="coreTeamPageNumber"
                [pageSizeOptions]="[25, 50, 75, 100]"
                (page)="onCoreTeamPageChange($event)"
                aria-label="Select page"
              >
              </mat-paginator>
            </mat-tab>

            <!-- Associated Projects -->
            <app-loader *ngIf="loading"></app-loader>
            <mat-tab label="Associated Project">
              <div class="pb-6 pt-6">
                <div
                  class="d-flex justify-between align-center account-detail-actions"
                >
                  <div class="search-block">
                    <mat-form-field
                      appearance="outline"
                      class="search-input"
                      [formGroup]="projectForm"
                    >
                      <input
                        type="text"
                        matInput
                        placeholder="Search"
                        formControlName="searchQuery"
                      />
                      <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="account-detail-table">
                <app-loader *ngIf="loading"></app-loader>
                <ng-container *ngIf="!loading">
                  <table
                    mat-table
                    [dataSource]="projectList"
                    matSort
                    (matSortChange)="onSortChangeProject($event)"
                  >
                    <ng-container matColumnDef="name">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header="projectName"
                        class="name"
                      >
                        Name
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="name-col table-ellipse"
                        title="{{ element?.name }}"
                      >
                        {{ element?.name }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="costModal">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="name"
                      >
                        Cost Model
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="name-col table-ellipse"
                        [ngClass]="{
                      'success-text success-light-bg': element.cost_modal === 'hourly',
                      'info-text info-light-bg': element.cost_modal === 'fixed',
                      }"
                        title="{{
                          element.cost_modal === 'hourly'
                            ? 'Hourly'
                            : element.cost_modal === 'fixed'
                            ? 'Fixed'
                            : element.cost_modal === 'daily'
                            ? 'Daily'
                            : '--'
                        }}"
                      >
                        {{
                          element.cost_modal === "hourly"
                            ? "Hourly"
                            : element.cost_modal === "fixed"
                            ? "Fixed"
                            : element.cost_modal === "daily"
                            ? "Daily"
                            : "--"
                        }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="budget">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header="fixedCost"
                        class="name"
                      >
                        Budget
                        {{
                          currencyProject ? " (" + currencyProject + ")" : ""
                        }}
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let element"
                        class="name-col table-ellipse"
                        title="{{
                          element.cost_modal === 'hourly'
                            ? 'NA'
                            : element.fixed_cost
                            ? (element.fixed_cost | number : '1.2-2')
                            : 'NA'
                        }}"
                      >
                        {{
                          element.cost_modal === "hourly"
                            ? "NA"
                            : element.fixed_cost
                            ? (element.fixed_cost | number : "1.2-2")
                            : "NA"
                        }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="name"
                      >
                        Status
                      </th>
                      <td mat-cell *matCellDef="let element">
                        <div
                          *ngIf="isStatusOngoing(element.status)"
                          class="success-text success-light-bg w-fit px-2 table-chip"
                        >
                          {{ getFormattedStatus(element.status) }}
                        </div>

                        <div
                          *ngIf="isStatusOnHold(element.status)"
                          class="warning-light-bg warning-text w-fit px-2 table-chip"
                        >
                          {{ getFormattedStatus(element.status) }}
                        </div>

                        <div
                          *ngIf="isStatusCompleted(element.status)"
                          class="info-text info-light-bg w-fit px-2 table-chip"
                        >
                          {{ getFormattedStatus(element.status) }}
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="totalResource">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        mat-sort-header
                        class="name"
                      >
                        Total Resources
                      </th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.resourceCount }}
                      </td>
                    </ng-container>
                    <tr
                      mat-header-row
                      class="account-detail-header"
                      *matHeaderRowDef="projectColumns"
                    ></tr>
                    <tr
                      mat-row
                      class="account-detail-body"
                      *matRowDef="let row; columns: projectColumns"
                    ></tr>
                  </table>
                </ng-container>
              </div>
              <div *ngIf="projectList.length === 0" class="text-center mt-6">
                <p class="required-star" style="font-size: 1.5rem">
                  No associated projects found.
                </p>
              </div>
              <mat-paginator
                *ngIf="projectList.length > 0"
                class="action-table-pagination expense activity"
                [length]="totalProjectRecords"
                [pageSize]="projectPageSize"
                [pageIndex]="projectPageNumber"
                [pageSizeOptions]="[25, 50, 75, 100]"
                (page)="onProjectPageChange($event)"
                aria-label="Select page"
              >
              </mat-paginator>
            </mat-tab>
            <mat-tab label="Activity">
              <div class="search-block mt-6">
                <ng-container *ngIf="!loading">
                  <mat-form-field
                    appearance="outline"
                    class="search-input"
                    [formGroup]="activityForm"
                  >
                    <input
                      type="text"
                      matInput
                      placeholder="Search"
                      (keydown.enter)="$event.preventDefault()"
                      formControlName="searchQuery"
                    />
                    <mat-icon matSuffix>search</mat-icon>
                  </mat-form-field>
                </ng-container>
              </div>
              <div class="activity-listing mt-6">
                <app-loader *ngIf="loading"></app-loader>

                <ng-container
                  *ngIf="activityLogsGrouped.length > 0; else noLogsMessage"
                >
                  <ng-container *ngFor="let group of activityLogsGrouped">
                    <div class="py-2">
                      <p class="body-bg p-2 fw-400 textXS">
                        {{ formatDate(group.date) }}
                      </p>
                      <div
                        *ngFor="let log of group.logs"
                        class="activity-update d-flex align-center"
                      >
                        <div class="activity-time">
                          <p class="p-2 text-grey4 fw-400 textXS">
                            {{ log.timestamp | date : "shortTime" : "UTC" }}
                          </p>
                        </div>
                        <div class="activity-info align-center d-flex mr-8">
                          <div class="avatar-smX mr-2">
                            <div
                              style="
                                background-color: #005f8e;
                                color: #fff;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 10px;
                              "
                            >
                              {{ getDefaultPhotoText(log.user.name) }}
                            </div>
                          </div>
                          <span
                            class="textS text"
                            title="{{ log.user.name }}"
                            >{{ log.user.name }}</span
                          >
                        </div>
                        <div class="activity-discription">
                          <p class="p-2 text-grey4 fw-400 textXS">
                            {{ log.activityDetail }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noLogsMessage>
                  <p
                    class="required-star"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                      font-size: 16px;
                    "
                  >
                    No activity logs found.
                  </p>
                </ng-template>
              </div>
              <mat-paginator
                *ngIf="activityLogsGrouped.length > 0"
                class="action-table-pagination expense activity"
                [length]="totalActivityRecords"
                [pageSize]="activityPageSize"
                [pageIndex]="activityPageNumber"
                [pageSizeOptions]="[25, 50, 75, 100]"
                (page)="onActivityPageChange($event)"
                aria-label="Select page"
              >
              </mat-paginator>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-drawer-container
  class="pannel-container sidebar accordian unset h-unset"
  [hasBackdrop]="true"
>
  <mat-drawer
    #drawer
    [mode]="side"
    position="end"
    class="details-popup"
    [opened]="isOpen"
    (openedChange)="isOpen = $event"
  >
    <div class="modal-header d-flex justify-between mb-6">
      <div class="d-flex align-center">
        <img src="/images/filter.png" alt="" class="mr-2" />
        <h2 class="mat-subtitle-1 mb-0 grey-text">Filter</h2>
      </div>
      <mat-icon class="pointer" (click)="drawer.toggle()">close</mat-icon>
    </div>
    <div class="content-wrapper mb-6 accordian">
      <cdk-accordion class="example-accordion">
        <cdk-accordion-item
          *ngFor="let item of items; let index = index"
          #accordionItem="cdkAccordionItem"
          class="example-accordion-item"
          role="button"
          tabindex="0"
          [attr.id]="'accordion-header-' + index"
          [attr.aria-expanded]="accordionItem.expanded"
          [attr.aria-controls]="'accordion-body-' + index"
        >
          <div
            class="example-accordion-item-header"
            (click)="accordionItem.toggle()"
          >
            <div class="d-flex align-center px-4">
              <span class="material-symbols-outlined chevron-icon">
                <img src="/images/icons/expand_more.svg" alt="dropdown-icon" />
              </span>
              <p class="mat-body-1 sec-text-color ml-2">{{ item }}</p>
            </div>
          </div>
          <div
            class="example-accordion-item-body"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body-' + index"
            [attr.aria-labelledby]="'accordion-header-' + index"
          >
            <div *ngIf="index === 0">
              <h2
                class="grey-text px-4 mat-body-1"
                *ngFor="let account of accounts"
                (click)="toggleAccountCheckbox($event, account)"
              >
                <label class="checkbox-label">
                  <mat-checkbox
                    [(ngModel)]="selectedAccountsMap[account.accountID]"
                    (change)="onAccountChange($event, account)"
                  >
                    <span
                      title="{{ account.name }}"
                      class="grey-text mat-body-1"
                    >
                      {{ account.name | slice : 0 : 35 }}
                      <span *ngIf="account.name.length > 35">...</span>
                    </span>
                  </mat-checkbox>
                </label>
              </h2>
            </div>

            <div *ngIf="index === 1">
              <h2
                class="grey-text px-4 mat-body-1"
                *ngFor="let center of costCenters"
                (click)="toggleCostCenterCheckbox($event, center)"
              >
                <label class="checkbox-label">
                  <mat-checkbox
                    [(ngModel)]="selectedCostCentersMap[center.cc_center_name]"
                    (change)="onCostCenterChange($event.checked, center)"
                  >
                    <span
                      title="{{ center.cc_center_name }}"
                      class="grey-text mat-body-1"
                    >
                      {{ center.cc_center_name | slice : 0 : 35 }}
                      <span *ngIf="center.cc_center_name.length > 35">...</span>
                    </span>
                  </mat-checkbox>
                </label>
              </h2>
            </div>
          </div>
        </cdk-accordion-item>
      </cdk-accordion>
      <div class="modal-footer mt-2 d-flex sec-text-center w-full flex-column">
        <div class="button-wrapper">
          <sft-button
            [label]="'Add Filter'"
            [color]="'primary'"
            [variant]="'flat'"
            [disableRipple]="false"
            [size]="'large'"
            class="filter-button"
            (click)="applyFilters()"
            [ngClass]="{ 'disabled-button': !hasSelectedFilters() }"
          ></sft-button>
        </div>
        <div class="button-wrapper mt-2">
          <sft-button
            [label]="'Clear'"
            [color]="'primary'"
            [variant]="'stroked'"
            [disableRipple]="false"
            [size]="'large'"
            class="filter-button"
            (click)="clearFilters()"
          ></sft-button>
        </div>
      </div>
    </div>
  </mat-drawer>
  <div class="overlay" *ngIf="isOpen"></div>
</mat-drawer-container>

import { Component, EventEmitter, Output } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Observable, tap } from 'rxjs';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { AccountService } from 'src/app/admin-portal/modules/account/services/account.service';

interface CostCenter {
  cc_center_name: any;
}

@Component({
  selector: 'app-cost-center-dialog-filter',
  templateUrl: './filter-cost-center-sidebar.component.html',
})
export class FilterCostCenterSidebarComponent {
  side!: MatDrawerMode;
  isOpen: boolean = false;
  items = ['Accounts', 'Cost Center'];
  expandedIndex = 0;
  costCenters: CostCenter[] = [];
  accounts: any[] = [];
  selectedAccounts: string[] = [];
  selectedCostCenters: CostCenter[] = [];

  // Using maps to manage selected states
  selectedAccountsMap: { [key: string]: boolean } = {};
  selectedCostCentersMap: { [key: string]: boolean } = {};

  @Output() filtersApplied = new EventEmitter<any>();

  constructor(
    private costCenterService: CostCenterService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.loadCostCenters().subscribe();
    this.loadAccounts().subscribe();
  }

  loadAccounts(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.accountService
      .getAccountList(0, MAX_LIMIT, '', 'createdAt', 'ASC')
      .pipe(
        tap((response: any) => {
          this.accounts = response.data.records;
          this.updateAccountCheckboxes();
        })
      );
  }

  loadCostCenters(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.costCenterService
      .getCostList(0, MAX_LIMIT, '', 'createdAt', 'ASC')
      .pipe(
        tap((response: any) => {
          this.costCenters = response.data.records;
          this.updateCostCenterCheckboxes();
        })
      );
  }

  updateAccountCheckboxes(): void {
    this.selectedAccounts.forEach((accountID) => {
      this.selectedAccountsMap[accountID] = true;
    });
  }

  updateCostCenterCheckboxes(): void {
    this.selectedCostCenters.forEach((center) => {
      this.selectedCostCentersMap[center.cc_center_name] = true;
    });
  }

  hasSelectedFilters(): boolean {
    return (
      this.selectedCostCenters.length > 0 || this.selectedAccounts.length > 0
    );
  }

  applyFilters(): void {
    if (this.hasSelectedFilters()) {
      this.filtersApplied.emit({
        selectedAccounts: this.selectedAccounts,
        selectedCostCenters: this.selectedCostCenters.map(
          (center) => center.cc_center_name
        ),
      });
      this.isOpen = false;
    }
  }

  clearFilters(): void {
    this.selectedAccounts = [];
    this.selectedCostCenters = [];
    this.selectedAccountsMap = {};
    this.selectedCostCentersMap = {};
    this.applyFilters();
    this.filtersApplied.emit({});
  }

  onAccountChange(event: any, account: any): void {
    if (event.checked) {
      this.selectedAccounts.push(account.accountID);
    } else {
      const index = this.selectedAccounts.indexOf(account.accountID);
      if (index > -1) {
        this.selectedAccounts.splice(index, 1);
      }
    }
  }

  onCostCenterChange(checked: boolean, center: CostCenter): void {
    if (checked && !this.selectedCostCenters.includes(center)) {
      this.selectedCostCenters.push(center);
    } else if (!checked && this.selectedCostCenters.includes(center)) {
      const index = this.selectedCostCenters.indexOf(center);
      if (index > -1) {
        this.selectedCostCenters.splice(index, 1);
      }
    }
  }

  toggleAccountCheckbox(event: any, project: any) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }

  toggleCostCenterCheckbox(event: any, center: CostCenter) {
    const checkbox = event.target.querySelector('mat-checkbox input');
    if (checkbox) {
      checkbox.click();
    }
  }
}
